<template>
  <div class="subscription-calculator">
    <div class="subscription-calculator__item">
      <div class="subscription-calculator__value">
        {{ bricks }}
      </div>
      <div class="subscription-calculator__label">
        {{ bricksLabel }}
      </div>
    </div>
    <MathSign name="multi" />
    <div class="subscription-calculator__item">
      <div class="subscription-calculator__value">
        {{ monthlyCostValue }}
      </div>
      <div class="subscription-calculator__label">
        {{ monthlyCostLabel }}
      </div>
    </div>
    <MathSign v-if="isOneTimePayment" name="multi" />
    <div v-if="isOneTimePayment" class="subscription-calculator__item">
      <div class="subscription-calculator__value">
        {{ `${months} ${$t('months_short')}` }}
      </div>
      <div class="subscription-calculator__label">
        {{ monthsLabel }}
      </div>
    </div>
      <MathSign name="equal" />
    <div class="subscription-calculator__item">
      <div class="subscription-calculator__value">
        {{ totalAmountValue }}
      </div>
      <div class="subscription-calculator__label">
        {{ labelTotalAmount }}
      </div>
    </div>
  </div>
</template>

<script>
import MathSign from './math-sign/MathSign'
import {moneyFormat} from '../../../shared/utils/moneyFormat'

export default {
  name: 'SubscriptionCalculator',
  components: {MathSign},
  SUBSCRIPTION_MONTHS_FREQUENCY: 1,
  props: {
    isOneTimePayment: {
      type: Boolean,
      required: true
    },
    childrenCount: {
      type: [Number, String],
      required: true
    },
    monthlyCost: {
      type: Number,
      default: 10
    },
    monthsCount: {
      type: Number,
      required: true
    },
    unitType: {
      type: String,
      required: true
    }
  },
  methods: {
    moneyFormat,
  },
  computed: {
    localeObj() {
      return `subscription_calculator.labels.${this.unitType}`
    },
    bricksLabel() {
      return this.$t(`${this.localeObj}.bricks`)
    },
    monthsLabel() {
      return this.$t(`${this.localeObj}.months`)
    },
    monthlyCostLabel() {
      return this.$t(`${this.localeObj}.monthly_cost`)
    },
    monthlyCostValue() {
      const basicStr = this.moneyFormat(this.monthlyCost)
      const suffix = this.$t('months_short')

      return `${basicStr}/${suffix}`
    },
    totalAmountValue() {
      const basicFormatVal = this.moneyFormat(this.totalAmount)
      const suffix = this.isOneTimePayment ? '' : `/${this.$t('months_short')}`
      return `${basicFormatVal}${suffix}`
    },
    bricks() {
      const STRING_TYPE = 'string'
      return typeof this.childrenCount === STRING_TYPE ? 0 : this.childrenCount
    },
    months() {
      return this.isOneTimePayment ? this.monthsCount : this.$options.SUBSCRIPTION_MONTHS_FREQUENCY
    },
    totalAmount() {
      return this.bricks * this.monthlyCost * this.months
    },
    labelTotalAmount() {
      const ONE_TIME_AMOUNT = 'one_time_amount'
      const CYCLIC_AMOUNT = 'cyclic_amount'
      const getText = (type) => this.$t(`${this.localeObj}.${type}`)

      return this.isOneTimePayment ? getText(ONE_TIME_AMOUNT) : getText(CYCLIC_AMOUNT)
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";

.subscription-calculator {
  margin: 0 0 1.7em;
  padding: 1.7em 0;
  border-color: #E4E7ED;
  border-width: 1px 0;
  border-style: solid;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include xs-smartphone {
    font-size: 10px;
  }
  @media screen and (max-width: 370px){
    font-size: 8px;
  }
  &__item {
    padding: 1em;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  &__value {
    font-weight: 700;
    font-size: 1.3em;
    color: #606266;
  }
  &__label {
    font-size: 0.9em;
    color: #909399;
  }
}
</style>
