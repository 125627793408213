<template>
  <el-form :model="form"
           :rules="formRules"
           label-position="top"
           size="medium"
           :status-icon="false"
           ref="form"
  >
    <slot :unitInfo="unit"
          :allowSubscription="allowSubscription"
          :brickToSubscribeCount="brickToSubscribeCount"
          :maxBrickCountExceeded="maxBrickCountExceeded"
          :availableBricksToSubscribeCount="availableBricksToSubscribeCount"

          :paymentForm="form"

          :isVolunteerCode="isVolunteerCode"
          :changeIsVolunteerCode="changeIsVolunteerCode"

          :isOneTimePayment="isOneTimePayment"
          :changeIsOneTimePayment="changeIsOneTimePayment"

          :selectedBrickOption="selectedBrickOption"
          :changeSelectedBrickOption="changeSelectedBrickOption"

          :selectedMonthsCount="selectedMonthsCount"
          :changeSelectedMonthCount="changeSelectedMonthCount"

          :backToUnitPage="goToUnitPage"

          :startPayment="startPayment"></slot>
  </el-form>
</template>

<script>
import {fetchUnitPaymentInfo} from '../../client/unit-page/services/unit-sevice'
import {aboveZeroNumberValidator} from '../../shared/validators/aboveZeroNumberValidator'
import {numberValueValidator} from '../../shared/validators/numberValueValidator'
import {
  CHILDREN_COUNT_OPTIONS,
  OTHER_CHILDREN_OPTION,
  MONTHS_COUNT_OPTIONS
} from '../shared/constants/countOptions'
import {mapState} from 'vuex'
import {requestCyclicSubscription, requestOneTimeSubscription} from '../shared/services/payment-service'
import {UNIT_PURPOSES_ROUTE_NAME} from '../../shared/constants/app-routes-names'
import {UNIT_TYPE_FAMILY} from '../../admin/units/shared/constants/units-constants'


export default {
  name: 'CyclicPayment',
  OTHER_CHILDREN_OPTION,
  data() {
    return {
      isVolunteerCode: false,
      isOneTimePayment: false,
      isLoading: false,
      unit: {
        unitTitle: '',
        children: 0,
        supportedChildren: 0,
        unitType: UNIT_TYPE_FAMILY
      },
      selectedBrickOption: 0,
      selectedMonthsCount: 0,
      form: {
        customChildrenCount: 5,
        volunteerCode: '',
      },
      formRules: {
        customChildrenCount: [
          {required: true, message: this.$t('validators.field_required')},
          {validator: aboveZeroNumberValidator, trigger: 'change'},
          {validator: numberValueValidator, trigger: 'change'},
        ]
      }
    }
  },
  created() {
    this.fetchUnitPreview()

    this.selectedBrickOption = CHILDREN_COUNT_OPTIONS[2]
    this.selectedMonthsCount = MONTHS_COUNT_OPTIONS[2].value

    this.form.volunteerCode = this.refererCode
    if (this.form.volunteerCode) {
      this.isVolunteerCode = true
    }
  },
  methods: {
    changeIsOneTimePayment(v) {
      this.isOneTimePayment = v
    },
    changeSelectedBrickOption(v) {
      this.selectedBrickOption = v
    },
    changeSelectedMonthCount(v) {
      this.selectedMonthsCount = v
    },
    changeIsVolunteerCode() {
      this.isVolunteerCode = !this.isVolunteerCode
    },
    async fetchUnitPreview() {
      this.isLoading = true
      const {unitID} = this.$route.params

      const {
        unitType,
        unitTitle,
        children,
        supportedChildren,
        monthlyCost
      } = await fetchUnitPaymentInfo(unitID)
      this.unit = {
        monthlyCost,
        unitType,
        unitTitle,
        children,
        supportedChildren
      }
      this.isLoading = false
    },
    requestPayment() {
      const {unitID} = this.$route.params
      const months = this.selectedMonthsCount
      const children = this.brickToSubscribeCount
      const {volunteerCode} = this.form
      const payload = {
        unitID,
        children,
        volunteerCode: this.isVolunteerCode ? volunteerCode : '',
      }
      if (this.isOneTimePayment) {
        return requestOneTimeSubscription({
          ...payload,
          months,
        })
      }
      return requestCyclicSubscription({
        ...payload,
      })
    },
    startPayment() {
      this.isLoading = true
      this.$refs.form.validate((valid) => {
        if (!valid) { return }

        this.requestPayment()
            .then((redirectUrl) => {
              window.location.href = redirectUrl
            })
            .catch((e) => {
              this.$message({
                type: 'error',
                message: this.$t(`alerts.payments.${e.data.message}`)
              })
            })
            .finally(() => {
              this.isLoading = false
            })
      })
    },
    goToUnitPage() {
      const {unitID} = this.$route.params
      this.$router.push({
        name: UNIT_PURPOSES_ROUTE_NAME,
        params: {
          unitID
        }
      })
    },
  },
  computed: {
    ...mapState('unitState', {
      refererCode: state => state.refererCode
    }),
    allowSubscription() {
      return this.unit.supportedChildren < this.unit.children
    },
    availableBricksToSubscribeCount() {
      return this.unit.children - this.unit.supportedChildren
    },
    brickToSubscribeCount() {
      const condition = this.selectedBrickOption === this.$options.OTHER_CHILDREN_OPTION
      return condition ? this.form.customChildrenCount : this.selectedBrickOption
    },
    maxBrickCountExceeded() {
      return this.brickToSubscribeCount > this.availableBricksToSubscribeCount && this.allowSubscription
    },

  }
}
</script>
