import i18n from '../../plugins/i18n'

const numberValueValidator = (_, value, callback) => {
  if (!Number(value)) {
    callback(new Error(`
      ${i18n.t('validators.number_value_required')}
    `))
  }
  callback()
}


export {
  numberValueValidator
}
