<template>
  <v-button ref="button" @click.prevent="changeState" :type="buttonType">
    <slot />
  </v-button>
</template>

<script>
import VButton from '../v-button/VButton'
export default {
  name: 'VButtonToggle',
  components: {VButton},
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    value() {
      this.blur()
    }
  },
  methods: {
    changeState() {
      this.$emit('input', !this.value)
    },
    blur() {
      this.$refs.button.blur()
    }
  },
  computed: {
    buttonType() {
      return this.value ? 'primary' : ''
    }
  }
}
</script>
