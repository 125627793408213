<template>
  <el-row>
    <el-col :lg="{span: 18, offset: 3}">
      <el-card>
        <img class="payment-provider-img" :src="$options.paymentProviderImage" alt="p24">
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import paymentProviderImage from '../../../../assets/images/p24_payment_methods.svg'

export default {
  name: 'PaymentProviderCard',
  paymentProviderImage,
}
</script>

<style scoped>
  .payment-provider-img {
    width: 100%;
  }
</style>
