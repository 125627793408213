<template>
  <el-tooltip placement="top">
    <v-icon color="#3f9eff" name="info"></v-icon>
    <template #content>
      <span v-html="$t('components.volunteer_input.tooltip')"></span>
    </template>
  </el-tooltip>
</template>

<script>
import VIcon from '../../../../components/ui/v-icon/VIcon'
export default {
  name: 'VolunteerTooltip',
  components: {VIcon}
}
</script>
