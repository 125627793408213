<template>
  <el-form-item :rules="rules"
                :label="$t('page_adoption_payment.fields.volunteer_code')"
                :prop="formItemProp"
  >
    <el-input @input="changeValue" :maxlength="$options.CODE_LENGTH" v-model="volunteerCode">
      <template #append>
        <volunteer-tooltip />
      </template>
    </el-input>
  </el-form-item>
</template>

<script>
import {volunteerCodeValidator, CODE_LENGTH} from './volunteerCodeValidator'
import VolunteerTooltip from '../volunteer-tooltip/VolunteerTooltip'

export default {
  name: 'VolunteerCodeInput',
  components: {VolunteerTooltip},
  CODE_LENGTH,
  props: {
    value: {
      type: String
    },
    formItemProp: {
      type: String
    }
  },
  data() {
    return {
      volunteerCode: this.value,
      rules: [
        {validator: volunteerCodeValidator, trigger: 'change'}
      ]
    }
  },
  methods: {
    changeValue(val) {
      this.$emit('input', val)
    }
  }
}
</script>

