<template>
  <div class="math-sign" :class="signClass"></div>
</template>

<script>
export default {
  name: 'MathSign',
  props: {
    name: {
      type: String,
      required: true,
    }
  },
  computed: {
    signClass() {
      return `math-sign--${this.name}`
    }
  }
}
</script>

<style scoped lang="scss">
  .math-sign {
    color: #909399;
    font-size: 1.2em;
    font-weight: 700;
    align-content: center;
    &--multi:before {
      content: 	'\00D7';
   }
    &--equal:before {
      content: 	'\003D';
   }
  }
</style>
