import {api} from '../../../../shared/services/backend-api'
import i18n from '../../../../plugins/i18n'

const CODE_LENGTH = 4

const findVolunteerByCode = (code) => {
  return api.get(`volunteers/${code}`)
}

const message_error = new Error(`${i18n.t('validators.volunteer_code_input')}`)


const volunteerCodeValidator = async (_, value, callback) => {
  if (value.length === CODE_LENGTH) {
    const {data: response} = await findVolunteerByCode(value)
    if (response) {
      callback()
    }
    callback(message_error)
  }
  callback(message_error)
}

export {
  volunteerCodeValidator,
  CODE_LENGTH
}
