import {api} from '../../../shared/services/backend-api'

const RESOURCE = 'orders'

const useVolunteerCode = (code) => {
  return {
    token: code
  }
}

const requestPurposePayment = async (paymentData) => {
  const {unitID, email, amount: price, volunteerCode} = paymentData
  
  const payload = {email, price}
  const {data} = await api.post(
    `${RESOURCE}/${unitID}/new-order`,
    payload,
    {
      params: {
        ...useVolunteerCode(volunteerCode)
      }
    }
  )
  
  return data.redirectURL
}

const requestCyclicSubscription = async (paymentData) => {
  const {unitID, children, volunteerCode} = paymentData
  
  const payload = {
    children
  }
  
  const {data} = await api.post(
    `${RESOURCE}/${unitID}/new-cyclic-subscription`,
    payload,
    {
      params: {
        ...useVolunteerCode(volunteerCode)
      }
    }
  )
  
  return data.redirectURL
  
}

const requestOneTimeSubscription = async (paymentData) => {
  const {unitID, children, months, volunteerCode} = paymentData
  
  const payload = {
    children,
    months
  }
  
  const {data} = await api.post(
    `${RESOURCE}/${unitID}/new-one-time-subscription`,
    payload,
    {
      params: {
        ...useVolunteerCode(volunteerCode)
      }
    }
  )
  
  return data.redirectURL
}

const fetchOrderStatusInfo = async (paymentID) => {
  const {data} = await api.get(`${RESOURCE}/${paymentID}`)
  
  return data
}

const renewSubscription = async (subscriptionData) => {
  const {
    isNewPricing: isUnitMonthlyCost,
    subscriptionID,
    selectedMonthsCount: months,
    ...rest
  } = subscriptionData
  
  const payload = {
    isUnitMonthlyCost,
    months,
    ...rest
  }
  const {data} = await api.post(`${RESOURCE}/${subscriptionID}/renew`, payload)
  return data.redirectURL
}

const fetchSubscriptionInfo = async (subscriptionID) => {
  const {data} = await api.get(`subscriptions/${subscriptionID}`)
  const {unitMonthlyCost: newPrice, subscriptionMonthlyCost: oldPrice, ...subscriptionInfo} = data
  return {
    newPrice,
    oldPrice,
    ...subscriptionInfo
  }
}

export {
  requestPurposePayment,
  requestOneTimeSubscription,
  requestCyclicSubscription,
  fetchOrderStatusInfo,
  renewSubscription,
  fetchSubscriptionInfo
}
