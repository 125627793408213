export const OTHER_CHILDREN_OPTION = 'other-option'
export const CHILDREN_COUNT_OPTIONS = [1, 2, 3, 4]
export const MONTHS_COUNT_OPTIONS = [
  { value: 1, pluralization: 0 },
  { value: 2, pluralization: 1 },
  { value: 3, pluralization: 1 },
  { value: 4, pluralization: 1 },
  { value: 5, pluralization: 2 },
  { value: 6, pluralization: 2 },
  { value: 7, pluralization: 2 },
  { value: 8, pluralization: 2 },
  { value: 10, pluralization: 2 },
  { value: 12, pluralization: 2 },
]
